import React, { Component } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Message } from "primereact/message";

import { UserService } from "../service/user.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  convertFullDate,
  GetToday,
  ConvertCalendar,
  GetTodayToString
} from "../utility/help";

interface WithdrawsState {
  balances: any;
  withdraws: any;
  activePage: any;
  field: any;
  begin: any;
  end: any;
  total: any;
  maxCount: number;
  searchText: any;
  findText: any;
  dropdownFind: any;
  cities: any;
  viewType: any;
  selectViewType: any;
  selectBalanceType : any;
  balanceType : any;
  level : any
  mode : string
}

export class DeporsitAndWithdraw extends Component<any, WithdrawsState> {
  userService: UserService  = new UserService();
  constructor(props: any) {
    super(props);

    let today = GetTodayToString();
    this.state = {
      maxCount: 1,
      activePage: 1,
      balances: 0,
      withdraws: [],
      field: "",
      begin: today.begin,
      end: today.end,
      total: [{  deposit : 0,depositCount : 0, withdraw : 0, withdrawCount : 0}],

      level : 1,
      searchText: "",
      findText: "",
      dropdownFind: "",
      mode:"deporsitAndwithdraw",

      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
      ],
 

      selectViewType: "all",
      selectBalanceType: "all",

      viewType: [
          { label: "모두 보기", value: "all" },
          { label: "충전", value: "deposit" },
          { label: "환전", value: "withdraw" },
          { label: "에이전트=> 에이전트", value: "agent_to_agent" },
      ],
      balanceType: [
          { label: "모두 보기", value: "all" },
          { label: "유저", value: "user" },
          { label: "에이전트", value: "agent" },
          { label: "센터", value: "center" },
      ],

    };


    this.get_deposit_and_withdraw(0, "all", this.state.selectBalanceType);
  }

  componentDidMount() {
  }

  handlePageChange = (page : any) => {
    this.setState({ activePage: page });
    // this.get_deposit_and_withdraw(page, this.state.selectViewType);
    this.get_deposit_and_withdraw(page, this.state.selectViewType, this.state.selectBalanceType);
  };
  // active={activePage}
  // take={20}
  // count={this.state.maxCount}
  // handlePageChange={this.handlePageChange}

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find((s: any) => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };


  get_deposit_and_withdraw = async (page: any, viewType: any, selectBalanceType: any) => {
      let begin = this.state.begin;
      let end = this.state.end;
      let searchText = this.state.searchText;
      let findText = this.state.dropdownFind;

      let data = await this.userService
          .get_deposit_and_withdraw_total_balance(
              page,
              begin.toLocaleString('sv-SE'),
              end.toLocaleString('sv-SE'),
              searchText,
              findText,
              viewType,
              selectBalanceType
          );
      this.setState({
          maxCount: data.maxCount,
          balances: data.balances,
          total: data.total,
          searchText: searchText,
          findText: findText,
          // viewType: viewType
      });
  };


  render() {


    const total = this.state.total

    console.log(total)

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                      <div className="card-title">입급/출금 완료</div>
                      <div className="p-md-12">

                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={new Date(this.state.begin)}
                            
                            // onChange={(e: any) =>this.setState({ begin : e.value.toLocaleString() })}
                            onChange={(e: any) => this.setState({ begin : e.value.toLocaleString('sv-SE') })}
                            showWeek={false}
                            locale={ConvertCalendar()}
                            showTime
                            />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={new Date(this.state.end)}

                            onChange={(e: any) => this.setState({ end : e.value.toLocaleString('sv-SE') })}

                            showWeek={false}
                            locale={ConvertCalendar()}
                            showTime
                            />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            style={{width : 100}}
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                            <label>검색</label>
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.get_deposit_and_withdraw(
                                  0,
                                  this.state.selectViewType,
                                  this.state.selectBalanceType,
                                );
                  
                              }}
                            />
                          </span>
                        </div>


                      </div>

               
                      <div className="p-grid form-group" style={{padding : '20px'}}>

                        <div className="p-col-12 p-md-12">
                          <div className="p-col-12 p-md-3">

                            <SelectButton
                                value={this.state.selectViewType}
                                options={this.state.viewType}
                                onChange={(event) => {

                                  if(event.value === 'agent_to_agent'){
                                    this.setState({ selectViewType: event.value, mode: "agent_deporsitAndwithdraw" });
                                  }
                                  else{
                                    this.setState({ selectViewType: event.value, mode: "deporsitAndwithdraw" });
                                  }
                                  
                                  this.get_deposit_and_withdraw(0, event.value, this.state.selectBalanceType);

                                }}
                            />
                          </div>


                          <div className="p-col-12 p-md-6">

                            <SelectButton
                                value={this.state.selectBalanceType}
                                options={this.state.balanceType}
                                onChange={(event) => {
                                    this.state.selectViewType;
                                    this.setState({ selectBalanceType: event.value });
                                    this.get_deposit_and_withdraw(0, this.state.selectViewType, event.value);
                                }}
                            />
                          </div>
                        </div>
                  
                        <div className="p-col-12 p-md-10">

                          
                        <div className="p-col-12 p-md-6">
                          <div className="p-grid form-group">

                          <DataTable
                            value={total}
                            paginatorPosition="both"
                            rows={20}
                            sortOrder={-1}
                            scrollable={true}
                          >
                            <Column
                              style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                              headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                              header="충전"
                              field="id"
                      
                              body={(rowData: any, column: any) => {
                                return <div>{numTomoeny(rowData.deposit)} ({rowData.depositCount})</div>;
                              }}
                            />

                          <Column
                              style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                              headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                              header="환전"
                              field="id"
                    
                              body={(rowData: any, column: any) => {
                                return <div>{numTomoeny(rowData.withdraw)}  ({rowData.withdrawCount})</div>;
                              }}
                            />
                            
                          <Column
                              style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                              headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                              header="충전-환전"
                              field="id"
                       
                              body={(rowData: any, column: any) => {
                                return <div>{numTomoeny(rowData.deposit - rowData.withdraw)}</div>;
                              }}
                            />

                          </DataTable>

                   
                          </div>

                        </div>


                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="p-col-12 ">
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                  <BalanceTable
                    mode={this.state.mode}
                    balances={this.state.balances}
                    level={this.state.level}
                    OnEditItem={this.OnEditItem}
                    OnSelectItem={this.OnSelectItem}
                  ></BalanceTable>

                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                </div>
              </div>
            </div>
          </div>
    );
  }
}
