import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BalanceService } from "../service/balance.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

interface BalanceState {
  logs: any;
  findText: any;
  searchText: any;
  maxCount: any;
  activePage: number;
  select: any;
  selectItem: any;
}

export class BalanceLog extends Component<any, BalanceState> {
  balanceService: BalanceService;
  constructor(props: any) {
    super(props);
    var today = new Date();

    this.state = {
      logs: [],
      findText: "",
      searchText: "",
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      select: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" }
      ]
    };
    this.handlePageChange = this.handlePageChange.bind(this);

    this.balanceService = new BalanceService();
  }

  handlePageChange(page: number) {
    // this.setState({ activePage: page });
    // this.balanceService
    //   .get_admin_balance(page, this.state.searchText, this.state.findText, "")
    //   .then(data => {
    //     this.setState({
    //       logs: data.logs,
    //       maxCount: data.maxCount
    //     });
    //   });
  }

  componentDidMount() {
    this.handlePageChange(1);
  }

  render() {
    let logs = this.state.logs;
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">  
              <div className="card card-w-title">
                <div className="card-title">벨런스 기록</div>

                <div className="p-col-12 ">
                  <div className="p-md-6">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <Dropdown
                            options={this.state.select}
                            value={this.state.findText}
                            onChange={(event: any) =>
                              this.setState({
                                findText: event.value
                              })
                            }
                            autoWidth={false}
                          />{" "}
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <InputText
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value
                              });
                            }}
                          />
                          <label>검색</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <Button
                            label="검색"
                            onClick={() => {
                              this.handlePageChange(0);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <LogBalanceTable logs={logs}></LogBalanceTable>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
