import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { UserService } from "../service/user.service";
import { AgentService } from "../service/agent.service";

import { HelpBox } from "../share/user/help-box";
import { BetSlotTable } from "../share/bet/bet-slot-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetTodayToString, ConvertCalendar } from "../utility/help";

interface MiniState {
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  cities: any;
  page: any;
  maxCount: any;
  summary: any;
}

export class BetSlot extends Component<any, MiniState> {
  userService: any = new UserService();
  agentService: any = new AgentService();

  constructor(props: any) {
    super(props);

    var today = new Date();

    this.state = {
      begin: new Date(today.setHours(0, 0, 0, 0)),
      // end: new Date(today.setHours(23, 59, 59, 999)),
      end: new Date(today.setDate(today.getDate() + 1)),
      userTableValue: [],
      summary: {},
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "userID" },
        { label: "에이전트", value: "agentID" },
      ],
    };

    // this.handlePageChange = this.handlePageChange.bind(this);

  }

  componentDidMount() {
    this.get_slot_bet_sum(1);
    this.get_slot_bet_list(1);
  }



  get_slot_bet_sum = (page: any) => {
    this.agentService
      .agent_get_slot_none_rolling_sum(
        page,
        this.state.findText,
        this.state.searchText,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE')
      )
      .then((s: any) => {
        if (s.status === "success") {
          this.setState({
            summary: s.summary,
          });
        }
      });
  };


  get_slot_bet_list = (page: any) => {
    this.agentService
      .agent_get_slot_list(
        page,
        this.state.findText,
        this.state.searchText,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE')
      )
      .then((s: any) => {
        if (s.status === "success") {
          this.setState({
            userTableValue: s.slots,
            maxCount: s.maxCount,
          });
        }
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }



  handleSumChange = (page: any) => {
    this.setState({ page: page });
    this.get_slot_bet_list(page);
    this.get_slot_bet_sum(page);

  };

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_slot_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title" style={{ padding: "0px" }}>

                  <div className="p-col-12 ">
                    <div className="p-md-11">
                      <div className="card-title">슬롯 배팅정보</div>
                      <div className="card-title"  style={{color: 'red'}}>ex ) 1. 최대 배팅 내역기록 보관은 1주입니다</div>
                      <div className="card-title"  style={{color: 'red'}}>ex ) 2. 롤링금은 게임사에서 들어오는 자료로 적립 됩니다. (내역은 최대 6시간 지연될수 있습니다)</div>
                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                          {/* <Calendar
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={new Date(this.state.begin)}
                            showWeek={false}
                            hourFormat="24"
                            onChange={(e: any) =>{
                              this.setState({ begin: e.value })
                            }}
                            locale={ConvertCalendar()}
                          /> */}
                              <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                value={new Date(this.state.begin)}
                                                showWeek={false}
                                                hourFormat="24"
                                                onChange={(e: any) =>{
                                                this.setState({ begin: e.value })
                                                }}
                                                locale={ConvertCalendar()}
                                            />
                        </div>
                        <div className="p-col-12 p-md-2">

                        <Calendar
                                                placeholder="End"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                value={new Date(this.state.end)}
                                                showWeek={false}
                                                hourFormat="24"
                                                onChange={(e: any) =>{
                                                this.setState({ end: e.value })
                                                }}
                                                locale={ConvertCalendar()}
                                            />
                          {/* <Calendar
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            showWeek={false}
                            value={this.state.end}
                            hourFormat="24"
                            onChange={(e: any) =>
                              this.setState({ end: e.value })
                            }
                            locale={ConvertCalendar()}
                          /> */}
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.findText}
                            onChange={(event: any) =>
                              this.setState({ findText: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.handleSumChange(0);
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <DataTable
                            style={{ padding: "0px" }}
                            value={this.state.summary}
                            paginatorPosition="both"
                            rows={20}
                          >
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="error" text="BET" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.bet)}</span>
                                  </div>
                                );
                              }}
                            />
                            {/* <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="info" text="WIN" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.win)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={
                                <Message severity="info" text="BET - WIN" />
                              }
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>
                                      {numTomoeny(rowData.bet - rowData.win)}
                                    </span>
                                  </div>
                                );
                              }}
                            /> */}

                          </DataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={30}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <BetSlotTable
                  mode={"bet"}
                  bets={this.state.userTableValue}
                ></BetSlotTable>

                <CustomPagination
                  active={activePage}
                  take={30}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
