import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, convertAgentBenefitBalanceText, ConvertCalendar, GetTodayToString, GetYesterOnlyDay} from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";

interface Props {
    agentId?: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];

    level : number
    benefits : any;
    totalBalance : any;
    viewType: any;


}

export class CalculateAgentCSWinLose extends Component<Props, BalanceState> {
    balanceService: BalanceService = new BalanceService();
    agentService: AgentService = new AgentService();

    constructor(props: Props) {
        super(props);

        let today = GetYesterOnlyDay();

        // const today = new Date();
        // const begin = new Date(
        //     `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        // );

        // today.setMonth(today.getMonth() + 1);

        // const end = new Date(
        //     `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        // );

        this.state = {
            begin: today.begin,
            end: today.end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            level : 1,
            benefits : [],
            totalBalance : {},
            viewType: "balance",

        };

        this.handlegetSubAgentCalculate(this.props.agentId,  today.begin,  today.end);
        this.handleGetAgentBenefit()

        console.log('')
    }

    
    
    handleGetAgentBenefit = () => {
        this.agentService.agent_get_benefits().then(s => {
        if (s.status == "success") {
            this.setState({ benefits: s.benefits });
        }
        });
    };

    handlegetSubAgentCalculate(agentId ,begin: any, end: any) {
        this.balanceService.admin_get_sub_agent(agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

                let totalBalance = {}
                for (let balances of Object.values(data.agentCombine) as any) {
                  for (let key of Object.keys(balances as [])) {
      
                        if (typeof (balances[key]) === 'number') {
                          if (totalBalance[key] == null) {
                            totalBalance[key] = 0
                          }
                          totalBalance[key] += Number(balances[key] as Number)
                        }
                        else if (typeof (balances[key]) === 'object' && balances[key] !=  null) {

                          for (let key2 of Object.keys(balances[key] as [])) {
      
                            if (typeof (balances[key][key2]) === 'number') {
                              if (totalBalance[`${key}.${key2}`] == null) {
                                totalBalance[`${key}.${key2}`] = 0
                              }
      
                              totalBalance[`${key}.${key2}`] += Number(balances[key][key2] as Number)
                            }
                          }
                        }
                    }
                  }

                  const combin = Object.values(data.agentCombine)
                  combin.sort(function (a: any, b: any) {
                      if (a.agent_tree < b.agent_tree) return -1;
                      if (b.agent_tree < a.agent_tree) return 1;
                      return 0;
                  })
  

                  
                  for (let info of combin as any) {
                    let losingPersistent = 0
                    let CasionPersistent = 0
                    let SlotPersistent = 0

                    let LosingCasionPersistent = 0
                    let LosingSlotPersistent = 0

                    if (info.agent === 'jy5999' || info.agent === 'dlrhr01' || info.agent === 'hm5226') {
                        console.log(info.agent_tree)
                    }
                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalWinLose == null) {
                            main.totalWinLose = 0
                            main.tCasionRoll = 0
                            main.tSlotRoll = 0
                        }


                        let total = 0
                        //(((info.totalRealCasinoBet - info.totalRealCasinoWin) * (info.benefitCasinoLosing == null ? 0 : (main.benefitCasinoLosing - LosingCasionPersistent))) / 100) - ((info.totalRealCasinoBet / 100) * (main.benefitCasinoRolling - CasionPersistent))


                        // (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw + rowData.totalYDInBalance - rowData.totalInBalance)
                        // / 100) * rowData.benefitSlotLosing) - ((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)

                        // main.totalWinLose += (((info.totalDeposit + info.totalGiveDeposit + info.totalAdmindeposit - info.totalWithdraw - info.totalGiveWithdraw - info.totalAdminwithdraw + info.totalYDReadInBalance - info.totalRealInBalance) * (info.benefitSlotLosing == null ? 0 : (main.benefitSlotLosing - LosingSlotPersistent))) / 100) - ((info.totalRealSlotBet / 100) * (main.benefitSlotRolling - SlotPersistent))

                        
                        main.totalWinLose += ((((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) - (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                            info.totalYDReadInBalance - info.totalRealInBalance) * (info.benefitSlotLosing == null ? 0 : (main.benefitSlotLosing - LosingSlotPersistent))) / 100) - ((info.totalRealSlotBet / 100) * (main.benefitSlotRolling - SlotPersistent))

                        // main.totalWinLose += (((info.totalRealSlotBet - info.totalRealSlotWin) * (info.benefitSlotLosing == null ? 0 : (main.benefitSlotLosing - LosingSlotPersistent))) / 100) - ((info.totalRealSlotBet / 100) * (main.benefitSlotRolling - SlotPersistent))


                        main.tCasionRoll += ((info.totalRealCasinoBet / 100) * (main.benefitCasinoRolling - CasionPersistent))
                        main.tSlotRoll += ((info.totalRealSlotBet / 100) * (main.benefitSlotRolling - SlotPersistent))


                        CasionPersistent = main.benefitCasinoRolling
                        SlotPersistent = main.benefitSlotRolling

                        LosingCasionPersistent = main.benefitCasinoLosing
                        LosingSlotPersistent = main.benefitSlotLosing



                    }

                }


                  this.setState({ agentInfo: combin , level : data.level, totalBalance : totalBalance});
      
            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }



        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title"> {this.props.agentId}</div>
                                <div className="p-grid form-group">

                                    <div className="p-col-8">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                        <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                value={new Date(this.state.begin)}
                                                showWeek={false}
                                                hourFormat="24"
                                                onChange={(e: any) =>{
                                                this.setState({ begin: e.value })
                                                }}
                                                locale={ConvertCalendar()}
                                            />

                                            {/* <Calendar
                                                // placeholder="Begin"
                                                // showIcon={true}
                                                // dateFormat="yy-mm-dd"
                                                // view="date"
                                                // value={this.state.begin}
                                                // onChange={(e) => this.setState({ begin: e.value })}
                                                // showWeek={false}
                                                // locale={ConvertCalendar()}
                                                // showTime
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                showWeek={false}

                                                value={new Date(this.state.begin)}
                                                onChange={(e: any) => this.setState({ begin: e.value.toLocaleString('sv-SE') })}

                                                showTime
                                                locale={ConvertCalendar()}

                                                /> */}
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                        <Calendar
                                                placeholder="End"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                value={new Date(this.state.end)}
                                                showWeek={false}
                                                hourFormat="24"
                                                onChange={(e: any) =>{
                                                this.setState({ end: e.value })
                                                }}
                                                locale={ConvertCalendar()}
                                            />
                                            {/* <Calendar
                                                // placeholder="Begin"
                                                // showIcon={true}
                                                // dateFormat="yy-mm-dd"
                                                // view="date"
                                                // value={this.state.end}
                                                // onChange={(e) => this.setState({ end: e.value })}
                                                // showWeek={false}
                                                // locale={ConvertCalendar()}
                                                // showTime
                                                placeholder="End"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                showWeek={false}
                                                value={new Date(this.state.end)}
                                                onChange={(e: any) => this.setState({ end : e.value.toLocaleString('sv-SE') })}

                                                // onChange={(e: any) =>
                                                //   this.setState({ end: e.value.toLocaleString() })
                                                // }
                                                showTime
                                                locale={ConvertCalendar()}
                                                /> */}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.props.agentId, this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <SelectButton
                                                value={this.state.viewType}
                                                options={
                                                    [
                                                        { label: "입출금 정산", value: "balance" },
                                                        { label: "카지노 Win-Lose 정산", value: "casino" },
                                                    ]
                                                }
                                                onChange={(event) => {
                                                    this.setState({ viewType: event.target.value });
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-4">
                                    <div className="card-title">에이전트 벨런스 </div>
                                    {
                                        Object.values(this.state.benefits).map((benefit : any) => {
                                        return (
                                            <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                            <label htmlFor="input">{convertAgentBenefitBalanceText(benefit.benefit_type)}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                        
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                            <label htmlFor="input">
                                            {benefit.percent}
                                            </label>
                                            </div>
                                
                                        
                                            </div>

                                        )
                                        })
                                    } 
                                </div>
                            </div>
                            </div>
                            <div className="card">
                              
                              {
                                  this.state.viewType === 'balance'  ? (
                                      
                                    <DataTable
                                    paginatorPosition="both"
                                    scrollable={true}
                                    value={this.state.agentInfo}
                                    rows={999}
                                    sortOrder={-1}
                                    style={{  padding: '0px'}}
                                    rowClassName={rowClass}
                                >

                                    {
                                        this.state.level <= 1 && (
                                            <Column
                                            style={{
                                                textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                            }}
                                            headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                            header="본사"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.level === 1) return (
                                                    <div>
                                                        <div>{rowData.agent}</div>
                                                        <div>{rowData.nick}</div>
                                                    </div>
                                                )
                                                return <></>;
                                                
                                                
                                            }}
                                        /> 
                                        )
                                    }
                                    {
                                        this.state.level <= 2 && (

                                
                                <Column
                                        style={{
                                            textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                        }}
                                        headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                        header="부본사"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 2) return (
                                                <div>
                                                    <div>{rowData.agent}</div>
                                                    <div>{rowData.nick}</div>
                                                </div>
                                            )
                                            return <></>;
                                            
                                            
                                        }}
                                    />
                                        )
                                    }
                                        {
                                            this.state.level <=  3 && (

                                                <Column
                                                    style={{
                                                        textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                                    }}
                                                    headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                                    header="총판"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 3) return (
                                                            <div>
                                                            <div>{rowData.agent}</div>
                                                            <div>{rowData.nick}</div>
                                                        </div>
                                                                )
                                                        return <></>;
                                                        
                                                        
                                                    }}
                                                />
                                            )
                                    }
                                    
                                    {
                                        this.state.level <=  4 && (

                                      <Column
                                        style={{
                                            textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px" 
                                        }}
                                        headerStyle={{ textAlign: "center",background: '#27765c' , width: "80px" , color : '#fff', borderRight: '1px solid #d8d8d8'}}
                                        header="대리점"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 4) return (
                                                <div>
                                                    <div>{rowData.agent}</div>
                                                    <div>{rowData.nick}</div>
                                                </div>
                                            )
                                            return <></>;
                                            
                                            
                                        }}
                                    />
                                        )
                                        }
                                
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        headerStyle={{ textAlign: "center",background: '#27765c' , color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 입금</div><div>총판 지급</div><div>센터 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        headerStyle={{ textAlign: "center",background: '#27765c' ,color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 출금</div><div>총판 회수</div><div>센터 회수</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8'  , width: "80px"}}
                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 수익</div><div>총판 수익</div><div>센터 </div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                                <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalAdmindeposit - rowData.totalAdminwithdraw)))}</div>
                                            </div>;
                                        }}
                                    />

                                    
                                    <Column                                        
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8'  , width: "80px"}}
                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                        header={<div> 총회원 루징</div>}
                                        body={(rowData: any, column: any) => {
                                                    // 총회원 루징

                                                    const total = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit -
                                                        rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw + rowData.totalYDInBalance - rowData.totalInBalance)

                                                    return <div>{numTomoeny(total)}</div>;
                                                }}
                                            />


                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                // header={<div>유저 보유 { process.env.REACT_APP_API_WINLOSE}</div>}
                                                header={<div><div>금일 보유금</div><div>전일 보유금</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'green' }}>{numTomoeny(rowData.totalInBalance)}</div>
                                                        <div style={{ color: 'green' }}>{numTomoeny(rowData.totalYDInBalance)}</div>
                                                    </div>;
                                                }}
                                            />
                             
                                         <Column
                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                             header={<div>
                                                 <div>카지노 Bet</div>
                                                 {process.env.REACT_APP_API_SHOW_WIN_BALANCE === 'Y'  && <div>카지노 Win</div>}
                                                 </div>}

                                             body={(rowData: any, column: any) => {
                                                 return <div>
                                                     <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>

                                                     {
                                                        process.env.REACT_APP_API_SHOW_WIN_BALANCE === 'Y'  && <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                                     }
                                                 </div>;
                                             }}
                                         />
                     
                      
                                         <Column
                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                             header={<div><div>카지노 롤링</div><div>프로테이지</div></div>}
                                             body={(rowData: any, column: any) => {
                                                 return <div>
                                                 <div style={{ color:  '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                 <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                                 </div>;
                                             }}
                                         />

                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                            headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                            header={<div><div>슬롯 Bet</div>
                                                 {process.env.REACT_APP_API_SHOW_WIN_BALANCE === 'Y'  && <div>슬롯 Win</div>}
                                            </div>}
                                            
                                            body={(rowData: any, column: any) => {
                                                return <div>
                                                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                    
                                                    {
                                                        process.env.REACT_APP_API_SHOW_WIN_BALANCE === 'Y'  && <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                    }
                                                </div>;

                                            }}
                                        />



                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                            header={<div><div>슬롯 롤링</div><div>슬롯 프로테이지</div></div>}
                                            body={(rowData: any, column: any) => {

                                                return <div>
                                                    <div style={{ color: '#ffc107' }}>{numTomoeny((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)}</div>
                                                    <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                </div>;
                                            }}
                                        />

                                        



                      
                                         <Column
                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px" }}
                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                             header={<div><div>정산금 (%)</div></div>}
                                             body={(rowData: any, column: any) => {
 

                                                const total =
                                                (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw + rowData.totalYDInBalance - rowData.totalInBalance)
                                                    / 100) * rowData.benefitSlotLosing) - ((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)

                                                    
                                                // <div style={{ color:  '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                // <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                                 return <div>
                                                         <div >{numTomoeny(total)}</div>
                                                        <div>{numTomoeny(rowData.totalWinLose)}</div>

                                                         <div>{rowData.benefitLosing} % - {rowData.benefitSlotLosing} % </div>
                                                 </div>;
                                             }}
                                         />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "80px"}}
                                        headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8', width: "80px"}}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>파트너 충전 {numTomoeny(this.state.totalBalance['realAgentChangeBalance'])} 
                                            </div><div>파트너 환전{numTomoeny(this.state.totalBalance['realAgentExchangeBalance'])}
                                            </div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                               <div style={{ color:  '#ffc107' }}>{numTomoeny( rowData.realAgentChangeBalance)}</div>
                                            <div style={{ color: 'green' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />
                                </DataTable>
                                  )

                                   : (
                                
                                    
                                    <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={99999}
                                    sortOrder={-1}
                                    scrollable
                                    scrollHeight="600px"
                                    headerColumnGroup={(
                                        <ColumnGroup>
                                            <Row>
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="에이전트" />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 수익</div><div>총판 수익</div></div>} />


                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>Casino Bet</div><div>Casino Win</div></div>} />

                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>카지노 루징</div></div>} />


                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>카지노 롤링</div></div>} />

                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>정산금</div><div>루징프로테이지%</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>에이전트 충전</div><div>에이전트 환전</div></div>} />


                                            </Row>
                                            {
                                                this.state.totalBalance && this.state.totalBalance['idx'] !== null && (

                                                    <Row >
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header="정산 합계" />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalDeposit'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalGiveDeposit'])}</div></div>} />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalWithdraw'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalGiveWithdraw'])}</div></div>} />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div> {numTomoeny(this.state.totalBalance['totalDeposit'] - this.state.totalBalance['totalWithdraw'])}</div>
                                                            <div> {numTomoeny(this.state.totalBalance['totalGiveDeposit'] - this.state.totalBalance['totalGiveWithdraw'])}</div></div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalCasinoBet'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalCasinoWin'])}</div></div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalCasinoLosing'])}</div></div>} />

                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            {numTomoeny(this.state.totalBalance['totalCasinoRolling'])}</div>} />
                                                        {/* 
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                        </div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                        </div>} /> */}


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            {numTomoeny(this.state.totalBalance['totalCasinoLosing'])}
                                                        </div>} />

                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(this.state.totalBalance['totalAgentChangeBalance'])}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(this.state.totalBalance['totalAgentExchangeBalance'])}</div>
                                                        </div>} />



                                                    </Row>
                                                )

                                            }


                                        </ColumnGroup >
                                    )}

                                >
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <>
                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                </a>
                                                <br />
                                                <a >{rowData.nick}
                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                </a>
                                            </>

                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>

                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />





                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                            </div>;

                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            // 총회원 루징


                                            return <div>
                                                <div style={{ color: rowData.totalCasinoBet - rowData.totalCasinoWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalCasinoBet - rowData.totalCasinoWin)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {


                                            return <div>
                                                <div style={{ color: '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            {/* 정산 */ }




                                            const total =
                                                (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw + rowData.totalYDInBalance - rowData.totalInBalance)
                                                    / 100) * rowData.benefitSlotLosing) - ((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)




                                            return <div>
                                                <div style={{ color: total > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny(total)}</div>

                                                <div>{rowData.benefitCasinoLosing} % - {rowData.benefitSlotLosing} %</div>
                                            </div>;


                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAgentChangeBalance)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAgentExchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />




                                </DataTable>
                                   )
                              }

                                  

 
                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
