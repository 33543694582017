import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import cookie from "react-cookies";
import { confirmAlert } from "react-confirm-alert";

// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Recaptcha from 'react-google-invisible-recaptcha';


import { UserService } from "../service/user.service";
import { AxiosMag } from "../service/axios.service";


interface State {
  id: string;
  pw: string;
  captcha : string;
  messageSent : boolean
}
export default class Login extends Component<any, State> {
  userService = new UserService();

  captcha = React.createRef() as any;
  constructor(props: any) {

    super(props);
    this.state = {
      id: "",
      pw: "",
      captcha : '',
      messageSent: false
    };
  }

  componentDidMount () {
    // loadCaptchaEnginge(4, 'red', 'blue',' lower'); 

    // loadCaptchaEnginge(4,'red', 'black','lower');

    
 };

  
  sendMessage = () => {
    this.captcha.execute();
  }

  
  // onResolved = () => {
  //   this.setState({messageSent: true})
  //   // Process Data //
  //     console.log(this.state);
  // }

 
  DoLogin = () => {
    
    let user_captcha = this.state.captcha;

    // if (validateCaptcha(user_captcha) !== true) {
    //   alert('화면에 보이는 4자리 숫자 또는 영문 4개를 입력해주세요');
    //   return 
    // }

    


    this.userService
      .do_login_agent(this.state.id, this.state.pw)
      .then((data : any) => {
        if (data.status == "success") {
          cookie.save("session", data.session, { path: "/" });


          AxiosMag.SetSession(data.session);
          window.location.href = "/#/dashboard";
          // this.setState({ user: data.user });
        } else if (data.status == "black") {

          confirmAlert({
            title: "에이전트",
            message: "해당 아이디는 로그인할수 없습니다. 관리자에게 문의 부탁드림니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
        } else {


          console.log(data)
          confirmAlert({
            title: "에이전트",
            message: "아이디 또는 비밀번호를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });

        }
      });
  };

  render() {
    // const [cookies, setCookie] = useCookies(["session"]);
    // this.state = { userId: cookie.load("userId") };

    let confirmation = this.state.messageSent ?
            (<div>Message Sent!</div>) : null;

    return (
      <div className="login-body">
        <div className="login-panel ui-fluid"  style={{height : '480px'}}>
          <div className="login-panel-header">
            <img
              src="assets/layout/images/login/logo-sapphire-color.png"
              alt="sapphire"
            />
          </div>
          <div className="login-panel-content">
            <div className="p-grid">
              <div className="p-col-12">
                <h1>SAPPHIRE NETWORK</h1>
                <h2>Welcome, please use the form to sign-in</h2>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    name="id"
                    style={{ width: "100%" }}
                    value={this.state.id}
                    onChange={(event: any) => {
                      this.setState({
                        id: event.target.value
                      });
                    }}
                  />
                  <label>아이디</label>
                </span>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    name="pw"
                    type="password"
                    style={{ width: "100%" }}
                    value={this.state.pw}
                    onChange={(event: any) => {
                      this.setState({
                        pw: event.target.value
                      });
                    }}
                  />
                  <label>비밀번호</label>
                </span>


              </div>
{/* 
              <div className="p-col-12">
                <LoadCanvasTemplate style={{width :'200px'}} width={500}/>
              </div>

              
              <div className="p-col-12">
                <input placeholder="문자 4개를 입력해주세요" type="text" 
                style={{fontSize:26}}
                value={this.state.captcha}
                               onChange={(e : any) => this.setState({captcha : e.target.value})}
              ></input>
              </div> */}

              <div className="p-col-6">
              </div> 

              <div className="p-col-6" style={{ textAlign: "right" }}>
                <Button
                  label="로그인"
                  onClick={() => this.DoLogin()}
                  style={{ width: "100%" }}
                />
              </div>
{/* 
              <Recaptcha
                        ref={ ref => this.captcha = ref }
                        sitekey="6LdthtobAAAAANTnVD-RwMWcYpFMMWMWacyfJZP9"
                        onResolved={ this.DoLogin }
                    /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
