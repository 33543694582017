//eBetting\admin\src\service\WORK\eBetting\admin\src\service\AxiosService.js;
import { AxiosMag } from "./axios.service";

export class UserService {
  do_login_agent = async (id: string, pw: string) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_do_login", {
      id: id,
      pw: pw,
    });
  };


  admin_get_update_all_balance = async () => {
    return await AxiosMag.SendMessageByPost("/balance/admin_get_update_all_balance", {
    });
  };

  

  get_user_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_all_user_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
    });
  };

  
  agent_get_user_detail = async (
    userId: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_user_detail", {
      userId :userId 
    });
  };

  get_user_benefit = async (
    userId: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/user/get_user_benefit", {
      userId :userId 
    });
  };
  
  
  
  agent_change_user_pw = async (
    userId: any,
    userPW: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_change_user_pw", {userId,userPW});
  };

  admin_update_user_benefit_casino = async (
    userId: any,
    rollingCasino: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/user/admin_update_user_benefit_casino", {rollingCasino, userId});
  };

  admin_update_user_benefit_slot = async (
    userId: any,
    rollingSlot: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/user/admin_update_user_benefit_slot", {rollingSlot, userId});
  };
  
  
  
  get_user_health_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/user_health_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
    });
  };
  // admin_user_stause_change = async (userId: string, status: string) => {
  //   return await AxiosMag.SendMessageByPost("/user/admin_user_stause_change", {
  //     userId: userId,
  //     status: status
  //   });
  // };

  agent_get_deposit_and_withdraw = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any,
    balanceType: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/user/agent_get_deposit_and_withdraw",
      {
        skip: page,
        begin: begin,
        end: end,
        searchText: searchText,
        findText: findText,
        balanceType: balanceType,
      }
    );
  };

  get_deposit_and_withdraw_total_balance = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any,
    viewType: any,
    selectBalanceType: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/user/agent_get_deposit_and_withdraw_total_balance",
      {
        skip: page,
        begin: begin,
        end: end,
        searchText: searchText,
        findText: findText,
        viewType: viewType,
        selectBalanceType: selectBalanceType
      }
    );
  };

  agent_get_match_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_match_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };

  get_match_detail_by_betId = async (idx: any) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_match_detail", {
      idx: idx,
    });
  };

  agent_get_live_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_live_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };

  agent_get_mini_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/agent_get_minis_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };
  // get_reg_user_list = async (
  //   page: any,
  //   find: any,
  //   search: any,
  //   type: any,
  //   sort: any,
  //   mode: any
  // ) => {
  //   return await AxiosMag.SendMessageByPost("/user/admin_get_reg_user_list", {
  //     skip: page,
  //     status: mode,
  //     sortType: type,
  //     sort: sort,
  //     find: find,
  //     search: search
  //   });
  // };

  get_admin_user_detail = async (userID: any) => {
    return await AxiosMag.SendMessageByPost("/user/admin_get_user_detail", {
      userid: userID,
    });
  };

  get_in_game_balance = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_get_user_balance", {
      id,
    });
  };
}
